import Typography from 'typography';
// @ts-ignore
import irvingTheme from 'typography-theme-irving';
// @ts-ignore
import noriegaTheme from 'typography-theme-noriega';

import { theme } from 'src/styles/theme';

// @ts-ignore
irvingTheme.overrideThemeStyles = ({ rhythm }, options, styles) => ({
	'h1,h2': {
		marginBottom: rhythm(1 / 3),
	},
	h1: {
		marginTop: '2rem',
	},
	'h3,h4,h5,h6': {
		marginBottom: rhythm(1 / 4),
	},
	'h2>a': {
		...styles.h1,
		color: theme.colors.text,
	},
	'h2>a:hover': {
		color: theme.colors.text,
	},
	ul: {
		marginTop: 0,
	},
	li: {
		marginBottom: rhythm(1 / 6),
	},
	hr: {
		margin: '1rem 0',
		backgroundColor: theme.colors.text,
	},
	a: {
		color: theme.colors.primary,
		textDecoration: 'none',
	},
	'a:hover': {
		color: theme.colors.primary,
		textDecoration: 'underline',
	},
	blockquote: {
		color: theme.colors.primary,
		fontStyle: 'italic',
		fontSize: rhythm(0.85),
	},
	img: {
		marginBottom: 0,
	},
});

export const typography = new Typography({
	...irvingTheme,
	bodyFontFamily: noriegaTheme.bodyFontFamily,
});

export const { rhythm, scale } = typography;
