import { styled } from '@mui/material/styles';
import Link from 'next/link';
import React from 'react';

import { theme } from 'src/styles/theme';

export const isExactPath = (link: string, activePath: string): boolean => link === activePath;

export const isWithinPath = (link: string, activePath: string): boolean =>
	activePath.indexOf(link) > -1;

export const getLinkMetaForActivePath = (link: string, activePath: string) => {
	const exact = isExactPath(link, activePath);
	const within = isWithinPath(link, activePath);

	return { exact, within, active: exact || within };
};

interface Props {
	link: string;
	title: string;
	activePath: string;
}

export const MenuItem: React.FC<Props> = ({ link, title, activePath }) => {
	const { exact, active } = getLinkMetaForActivePath(link, activePath);

	return exact ? (
		<Item active={active}>{title}</Item>
	) : (
		<Link href={link} passHref legacyBehavior>
			<Item active={active}>{title}</Item>
		</Link>
	);
};

const Item = styled('h5')<{ active: boolean; exact?: boolean }>`
	text-transform: uppercase;
	text-decoration: none;
	cursor: ${({ exact }) => (exact ? undefined : 'pointer')};
	color: ${({ active }) => (active ? theme.colors.primary : theme.colors.text)};
	transition: color 0.3s linear;
	&:hover {
		color: ${theme.colors.primary};
		text-decoration: none;
	}
	margin: 0.4rem 0;
	@media (min-width: ${theme.breakpoints.md}px) {
		font-size: 1.2rem;
	}
`;
