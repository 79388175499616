import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { FaFacebook, FaGithub, FaInstagram, FaLinkedin, FaXTwitter } from 'react-icons/fa6';

import { Link } from 'src/components/next/Link';
import { SITE_META_DATA } from 'src/config/siteMetadata';
import { palette } from 'src/styles/palette';

interface Props {
	iconFill?: string;
}

export const Social: React.FC<Props> = ({ iconFill }) => {
	const { social } = SITE_META_DATA;
	const { linkedin, twitter, facebook, github, instagram } = social;
	return (
		<Wrapper>
			<Stack direction="row" spacing="0.35rem" sx={{ mt: '0.2rem' }}>
				{twitter && (
					<MetaItem href={`//twitter.com/${twitter}`} title="Twitter">
						<FaXTwitter size="1rem" fill={iconFill ?? palette.xtwitter} />
					</MetaItem>
				)}
				{linkedin && (
					<MetaItem href={`//linkedin.com/company/${linkedin}`} title="LinkedIn">
						<FaLinkedin size="1rem" fill={iconFill ?? palette.linkedin} />
					</MetaItem>
				)}
				{github && (
					<MetaItem href={`//github.com/${github}`} title="Github">
						<FaGithub size="1rem" fill={iconFill ?? palette.github} />
					</MetaItem>
				)}
				{facebook && (
					<MetaItem href={`//facebook.com/${facebook}`} title="Instagram">
						<FaFacebook size="1rem" fill={iconFill ?? palette.facebook} />
					</MetaItem>
				)}
				{instagram && (
					<MetaItem href={`//instagram.com/${instagram}`} title="Instagram">
						<FaInstagram size="1rem" fill={iconFill ?? palette.instagram} />
					</MetaItem>
				)}
			</Stack>
		</Wrapper>
	);
};

const Wrapper = styled('div')`
	display: flex;
	margin-bottom: rhythm(1.5);
`;

const MetaItem = styled(Link)`
	display: block;
	a {
		&:hover {
			text-decoration: none;
		}
	}
`;
