import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { transparentize } from 'polished';
import React from 'react';

import { theme } from 'src/styles/theme';

import { MenuItem } from './MenuItem';

interface Props {
	open: boolean;
}

export const Menu: React.FC<Props> = ({ open }) => {
	const { pathname } = useRouter();
	return (
		<Wrapper open={open}>
			<Content>
				<MenuItem link="/projecten" title="Projecten" activePath={pathname} />
				<MenuItem link="/contact" title="Contact" activePath={pathname} />
				<MenuItem link="/over-ons" title="Over ons" activePath={pathname} />
				<MenuItem link="/blog" title="Blog" activePath={pathname} />
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled('div')<{ open: boolean }>`
	position: absolute;
	top: 3rem;
	@media (min-width: ${theme.breakpoints.md}px) {
		top: 5rem;
	}
	right: 0.1rem;
	z-index: -2;
	transition:
		visibility 0.3s,
		opacity 0.3s linear;
	opacity: ${({ open }) => (open ? `1` : `0`)};
	visibility: ${({ open }) => (open ? `visible` : `hidden`)};
`;

const Content = styled('div')`
	right: 0;
	display: flex;
	flex-direction: column;
	padding: 1rem 2rem 1rem 1.5rem;
	box-shadow: 0.1rem 0.25rem 0.25rem 0.1rem rgba(0, 0, 0, 0.1);
	border-style: solid;
	border-width: 0.05rem;
	border-color: ${transparentize(0.95, theme.colors.text)};
	background-color: ${transparentize(0.05, theme.colors.headerBackground)};
`;
