export const palette = {
	primary: '#379dd7',
	primaryLight: '#b1e3f7',
	primaryDark: '#287099',
	secondary: '#2d9cdb',
	green: '#53f493',
	positive: '#45ab36',
	negative: '#bb0000',
	offWhite: '#f2f2f2',
	white: '#ffffff',
	black: '#000000',
	turquoise: 'turquoise',
	purple: 'purple',
	linkedin: '#0e76a8',
	github: '#000000',
	twitter: '#00acee',
	xtwitter: '#000000',
	instagram: '#dd2a7b',
	facebook: '#3b5998',
};
