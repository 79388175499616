import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { theme } from 'src/styles/theme';

export interface ContentProps extends React.PropsWithChildren {
	paddingTop?: boolean;
}

export const Content: React.FC<ContentProps> = ({ children, paddingTop = true }) => (
	<Box component="div">
		<Container paddingTop={paddingTop}>{children}</Container>
	</Box>
);

const Container = styled('div')<Pick<ContentProps, 'paddingTop'>>`
	padding-top: ${({ paddingTop }) => (paddingTop ? '3rem' : 0)};
	@media (min-width: ${theme.breakpoints.md}px) {
		padding-top: ${({ paddingTop }) => (paddingTop ? '5rem' : 0)};
	}
`;
