import { styled } from '@mui/material/styles';
import React from 'react';

import { Link } from 'src/components/next/Link';
import { theme } from 'src/styles/theme';

import { ResponsiveLayoutColumn } from '../LayoutColumn';
import { Logo as Logo_ } from '../logo/Logo';

import { Breadcrumbs } from './Breadcrumb';
import { Social } from './Social';

export interface FooterProps {
	marginTop?: boolean;
}

export const Footer: React.FC<FooterProps> = ({ marginTop = true }) => (
	<Wrapper marginTop={marginTop}>
		<ResponsiveLayoutColumn>
			<Container>
				<Left>
					<Copyright>&copy; {new Date().getFullYear()}</Copyright>
					<Link href="/" title="Home">
						<Logo />
					</Link>
				</Left>
				<Right>
					<Social />
				</Right>
			</Container>
			<Breadcrumbs />
		</ResponsiveLayoutColumn>
	</Wrapper>
);

const Wrapper = styled('div')<Pick<FooterProps, 'marginTop'>>`
	margin-top: ${({ marginTop }) => (marginTop ? '2rem' : 0)};
	padding: 2rem 0 8rem;
	background-color: ${theme.colors.footerBackground};
`;

const Copyright = styled('small')`
	display: block;
	margin-right: 0.25rem;
	margin-top: 0.05rem;
`;

const Container = styled('div')`
	display: flex;
	justify-content: space-between;
`;

const Left = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
`;

const Right = styled('div')``;

const Logo = styled(Logo_)`
	color: ${theme.colors.logo};
	height: 0.85rem;
	width: auto;
`;
