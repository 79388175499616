import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import React from 'react';

export interface LinkProps extends NextLinkProps, React.PropsWithChildren {
	title?: string;
	target?: string;
}

// Fix for `Invalid href passed to next/router: //` I prefer to go protocol-less ;-)
export const transform = (value: LinkProps['href']): LinkProps['href'] =>
	(value as string).replace(/^(\/\/){1}/, 'https://');

export const Link: React.FC<LinkProps> = ({ children, href, title, target, ...props }) => (
	<NextLink href={transform(href)} {...props} legacyBehavior passHref>
		<a title={title} target={target}>
			{children}
		</a>
	</NextLink>
);
