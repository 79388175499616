import { ClickAwayListener } from '@mui/base';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { transparentize } from 'polished';
import React, { useCallback, useEffect, useState } from 'react';

import { Link } from 'src/components/next/Link';
import { theme } from 'src/styles/theme';

import { ResponsiveLayoutColumn } from '../LayoutColumn';
import { Logo as Logo_ } from '../logo/Logo';

import { Burger } from './Burger';
import { Menu } from './Menu';

export interface HeaderProps {
	alwaysTransparent?: boolean;
}

export const Header: React.FC<HeaderProps> = ({ alwaysTransparent }) => {
	const [open, setOpen] = useState<boolean>(false);
	const [scrolled, setScrolled] = useState<boolean>(alwaysTransparent ?? false);

	const handleClickAway = () => {
		setOpen(false);
	};

	const handleScroll = useCallback((): void => {
		const doc = document.documentElement;
		const scrollTop = (window.scrollY || doc.scrollTop) - (doc.clientTop || 0);
		if (scrollTop >= 5) {
			setScrolled(true);
		} else if (scrollTop < 100) {
			setScrolled(false);
		}
	}, []);

	useEffect((): (() => void) => {
		handleScroll();
		window.addEventListener('scroll', handleScroll);
		return (): void => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [handleScroll]);

	return (
		<Wrapper scrolled={(alwaysTransparent ?? scrolled).toString()}>
			<ResponsiveLayoutColumn>
				<Container>
					<Left>
						<Link href="/" title="Home">
							<LogoContainer>
								<Logo />
							</LogoContainer>
						</Link>
					</Left>
					<ClickAwayListener onClickAway={handleClickAway}>
						<Right>
							<Menu open={open} />
							<Burger open={open} setOpen={setOpen} />
						</Right>
					</ClickAwayListener>
				</Container>
			</ResponsiveLayoutColumn>
		</Wrapper>
	);
};

const Wrapper = styled(Box)<{ scrolled: string }>`
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.95);
	transition: box-shadow 500ms;
	${({ scrolled }) =>
		scrolled === 'true' && `box-shadow: 0 0 5rem 0 ${transparentize(0.95, 'black')};`};
`;

const LogoContainer = styled(Box)`
	box-shadow: none;
	color: inherit;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`;

const Container = styled('div')`
	display: flex;
	justify-content: space-between;
	height: 3rem;
	@media (min-width: ${theme.breakpoints.md}px) {
		height: 5rem;
	}
`;

const Left = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`;

const Right = styled('div')`
	position: relative;
`;

const Logo = styled(Logo_)`
	color: ${theme.colors.logo};
	height: 1.5rem;
	@media (min-width: ${theme.breakpoints.md}px) {
		height: 2.2rem;
	}
	width: auto;
`;
