import { lighten } from 'polished';

import { Breakpoints, BREAKPOINTS } from 'src/styles/breakpoints';

import { Colors } from './colors';
import { palette } from './palette';

export interface Theme {
	colors: Colors;
	breakpoints: Breakpoints;
}

const colors: Colors = {
	primary: palette.primary,
	link: palette.primary,
	text: palette.black,
	mainBackground: palette.white,
	panelBackground: palette.white,
	headerBackground: palette.white,
	footerLogo: palette.instagram,
	footerBackground: lighten(0.4, palette.primary),
	logo: palette.instagram,
	icon: palette.black,
	cardBackground: palette.white,
	cardBorder: lighten(0.85, palette.black),
};

export const theme: Theme = {
	colors,
	breakpoints: BREAKPOINTS,
};
