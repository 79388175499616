import { styled } from '@mui/material/styles';
import React from 'react';
import { MdClose, MdMenu } from 'react-icons/md';

import { theme } from 'src/styles/theme';

interface Props {
	open: boolean;
	setOpen: (value: boolean) => void;
}

export const Burger: React.FC<Props> = ({ open, setOpen }) => (
	<Wrapper onClick={() => setOpen(!open)}>{open ? <Cross /> : <Bars />}</Wrapper>
);

const Wrapper = styled('div')`
	display: flex;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	cursor: pointer;
	width: 2rem;
	@media (min-width: ${theme.breakpoints.md}px) {
		width: 2.4rem;
	}
	align-items: center;
	justify-content: space-around;
`;

const Bars = styled(MdMenu)`
	width: 2rem;
	height: 2rem;
	@media (min-width: ${theme.breakpoints.md}px) {
		width: 2.4rem;
		height: 2.4rem;
	}
`;

const Cross = styled(MdClose)`
	width: 2rem;
	height: 2rem;
	@media (min-width: ${theme.breakpoints.md}px) {
		width: 2.4rem;
		height: 2.4rem;
	}
`;
