import { styled } from '@mui/material/styles';
import React from 'react';

import { theme } from 'src/styles/theme';
import { rhythm } from 'src/utils/typography';

export const LayoutColumn: React.FC<React.PropsWithChildren> = ({ children }) => (
	<Wrapper>{children}</Wrapper>
);

export const ResponsiveLayoutColumn: React.FC<React.PropsWithChildren> = ({ children }) => (
	<ResponsiveWrapper>{children}</ResponsiveWrapper>
);

const Wrapper = styled('div')`
	margin: 0 auto;
	max-width: ${rhythm(34)};
	padding: 0 ${rhythm(3 / 4)};
`;

const ResponsiveWrapper = styled(Wrapper)`
	@media (min-width: ${theme.breakpoints.xl}px) {
		margin-left: 1rem;
		margin-right: 1rem;
		max-width: 100%;
	}
`;
