import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import React, { Fragment, useEffect, useState } from 'react';
import { MdChevronRight, MdHome } from 'react-icons/md';

import { Link } from 'src/components/next/Link';

interface Crumb {
	title: string;
	href: string;
}

export const Breadcrumbs: React.FC = () => {
	const router = useRouter();
	const [breadcrumbs, setBreadcrumbs] = useState<Crumb[]>([]);

	useEffect(() => {
		if (router) {
			const paths = router.asPath.split('?')[0].split('/');
			paths.shift();

			const crumbs = paths.map((path, i) => ({
				title: path.replace(/-/g, ' '),
				href: `/${paths.slice(0, i + 1).join('/')}`,
			}));

			if (crumbs.length <= 1 && crumbs[0]?.href === '/') crumbs.shift();
			setBreadcrumbs(crumbs);
		}
	}, [router]);

	if (!breadcrumbs.length) return null;
	const lastIndex = breadcrumbs.length - 1;

	return (
		<nav aria-label="breadcrumbs">
			<small>
				<Link href="/" title="Home">
					<Home />
				</Link>
				<Separator />
				{breadcrumbs.map(({ title, href }, i) => (
					<Fragment key={href}>
						{i === lastIndex ? (
							title
						) : (
							<Link href={href} title={title}>
								{title}
							</Link>
						)}
						{i < lastIndex && <Separator />}
					</Fragment>
				))}
			</small>
		</nav>
	);
};

const Home = styled(MdHome)`
	margin-bottom: -0.15rem;
`;

const Separator = styled(MdChevronRight)`
	margin-bottom: -0.15rem;
`;
