import { Box } from '@mui/material';
import React from 'react';

import { Content, ContentProps } from './Content';
import { Footer, FooterProps } from './footer/Footer';
import { Header, HeaderProps } from './header/Header';

interface LayoutProps extends React.PropsWithChildren {
	header?: HeaderProps;
	content?: ContentProps;
	footer?: FooterProps;
}

export const Layout: React.FC<LayoutProps> = ({
	children,
	header: headerProps,
	content: contentProps,
	footer: footerProps,
}) => {
	return (
		<>
			<Header {...headerProps} />
			<Box
				component="div"
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					minHeight: '100vh',
				}}
			>
				<Content {...contentProps}>{children}</Content>
				<Footer {...footerProps} />
			</Box>
		</>
	);
};
